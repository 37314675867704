// import profile from "../../assets/profile.png";
import "./index.scss";
// import aqualife from "../../../public/assets/aqualife.jpg";

const Home3 = () => {
  return (
    <section className="home-3">
      <div className="owl-carousel owl-theme owl-banner">
        <img src="assets/banner-1.jpeg" alt="" />
        <img src="assets/banner-1.jpeg" alt="" />
        <img src="assets/banner-1.jpeg" alt="" />
        <img src="assets/banner-1.jpeg" alt="" />
        <img src="assets/banner-1.jpeg" alt="" />
        <img src="assets/banner-1.jpeg" alt="" />
      </div>
      <div className="row bg-secondary bg-opacity-25">
      <h2 className="fw-bold pt-5 ps-5">Blogs</h2>
        <div className="col-lg-8 blogs  mt-4">
          <div className="d-lg-flex border bg-white shadow mb-4 rounded">
            <span className="fs-5 date w-max p-1 px-2 rounded tag">
              Cinematographer
            </span>
            <img
              src="https://st4.depositphotos.com/10440988/30789/i/600/depositphotos_307899352-stock-photo-sphinx-blenny-fish-aidablennius-sphynx.jpg"
              className="blogImg img-fluid"
              alt=""
            />
            <div className="d-flex flex-column text">
              <span className="bg-opacity-25  date-pill w-max p-2 rounded">
                25 Jan 2022
              </span>
              <h3 className="mt-4">Aqualife Photography</h3>
              <p className="fs-5 mt-4 ">
                There are many variations of passages of Lorem ipsum available,
                but the majority have suffesred alteration in some form, by
                injected humour, or randomised words which doesn't look even
                slightlt believeabl....
              </p>
            </div>
          </div>
          <div className="d-lg-flex border bg-white shadow mb-4 rounded">
            <span className="fs-5 date w-max p-1 px-2 rounded tag">
              Video Editor
            </span>
            <img
              src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1622098961-dan-silva-pxx9ssopxti-unsplash.jpg"
              className="blogImg img-fluid"
              alt=""
            />
            <div className="d-flex flex-column text">
              <span className="bg-opacity-25 date-pill w-max p-2 rounded">
                21 Dec 2021
              </span>
              <h3 className="mt-4">Creative Live</h3>
              <p className="fs-5 mt-4 ">
                Creative Live hosts some of the best and most creative
                instructors in the Photography business today. For instance,
                they offer courses from starting your Photography business to
                mastering a photo edit...
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 blog-sidePanel mt-4">
          <div className="position-relative images-card mb-2 rounded">
            <img
              src="https://st4.depositphotos.com/10440988/30789/i/600/depositphotos_307899352-stock-photo-sphinx-blenny-fish-aidablennius-sphynx.jpg"
              className=" img-fluid rounded"
              alt=""
            />
            <div className="details">
              <h4 className="text-light">Aqualife Photography</h4>
              <span className="p-2 rounded date">
                25 Jan 2022
              </span>
            </div>
          </div>
          <div className="position-relative images-card mb-2 rounded">
            <img
              src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1622098961-dan-silva-pxx9ssopxti-unsplash.jpg"
              className=" img-fluid rounded"
              alt=""
            />
            <div className="details">
              <h4 className="text-light">Aqualife Photography</h4>
              <span className="p-2 rounded date">
                25 Jan 2022
              </span>
            </div>
          </div>
          <div className="position-relative images-card rounded mb-2">
            <img
              src="https://st4.depositphotos.com/10440988/30789/i/600/depositphotos_307899352-stock-photo-sphinx-blenny-fish-aidablennius-sphynx.jpg"
              className=" img-fluid rounded"
              alt=""
            />
            <div className="details">
              <h4 className="text-light">Aqualife Photography</h4>
              <span className="p-2 rounded date">
                25 Jan 2022
              </span>
            </div>
          </div>
          <div className="position-relative images-card rounded">
            <img
              src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1622098961-dan-silva-pxx9ssopxti-unsplash.jpg"
              className=" img-fluid rounded"
              alt=""
            />
            <div className="details">
              <h4 className="text-light">Aqualife Photography</h4>
              <span className="p-2 rounded date">
                25 Jan 2022
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials row my-5">
        <div className="col-lg-8">
          <p className="fs-2">Testimonials</p>
          <div className="row mt-5">
            <div className="col-lg-4 mb-2">
              <div className="border p-4">
                <div className="d-flex align-items-center">
                  <img
                    src="https://blog.hubspot.com/hubfs/Customer-testimonial-page.jpg"
                    className="rounded-circle"
                    alt=""
                  />
                  <div className="text">
                    <h5>Amma Watson</h5>
                    <span>Amritsar, Punjab</span>
                  </div>
                </div>
                <div className="card">
                  <div className="comment">
                    I Love this website as I had booked a Photographer for my
                    Sisters Birthday he did Awesome Photograph...
                    <br />
                    <a
                      className="text-decoration-none text-primary fw-bold "
                      href="/"
                    >
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <div className="border p-4">
                <div className="d-flex align-items-center">
                  <img
                    src="https://biology.mit.edu/wp-content/uploads/2020/07/Maddy_Kline_Testimonial_3-420x236.jpg"
                    className="rounded-circle"
                    alt=""
                  />
                  <div className="text">
                    <h5>Ed Sheeran</h5>
                    <span>Amritsar, Punjab</span>
                  </div>
                </div>
                <div className="card">
                  <div className="comment">
                    I Love this website as I had booked a Photographer for my
                    Sisters Birthday he did Awesome Photograph...
                    <br />
                    <a
                      className="text-decoration-none text-primary fw-bold "
                      href="/"
                    >
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="border p-4">
                <div className="d-flex align-items-center">
                  <img
                    src="https://photoheads.co.uk/wp-content/uploads/2020/05/headshot-with-client-testimonial.jpg"
                    className="rounded-circle"
                    alt=""
                  />
                  <div className="text">
                    <h5>Ishaan Tandon</h5>
                    <span>Amritsar, Punjab</span>
                  </div>
                </div>
                <div className="card">
                  <div className="comment">
                    This is Genuine Website I got a job and also completed and
                    my payment was release as soon a I finish...
                    <br />
                    <a
                      className="text-decoration-none text-primary fw-bold "
                      href="/"
                    >
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 faq text-center">
          <span className="fs-3  ps-4 text-secondary">
            Frequently Asked Question
          </span>
          <div className="accordion mt-2" id="accordionExample">
            <div className="accordion-item mb-4">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="text-primary fw-bold">1.</span>
                  <div className="d-flex justify-content-around w-100">
                    <span className="fw-bold">
                      How Can I register myself at Jobs.in?
                    </span>
                  </div>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body ">
                  You should visit Aura Hotel Jobs Website. Register yourself By
                  filing up the registration form.
                </div>
              </div>
            </div>
            <div className="accordion-item mb-4">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span className="text-primary fw-bold">2.</span>
                  <div className="d-flex justify-content-around w-100">
                    <span className="fw-bold">
                      Is it my resume visible to all employees?
                    </span>
                  </div>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
               <div className="accordion-body ">
                  Yes, your resume once uploaded to website is visibe to every employees.
                </div>
              </div>
            </div>
            <div className="accordion-item mb-4">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <span className="text-primary fw-bold">3.</span>
                  <div className="d-flex justify-content-around w-100">
                    <span className="fw-bold">
                      Is it my resume visible to all employees?
                    </span>
                  </div>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body ">
                  Yes, your resume once uploaded to website is visibe to every employees.
                </div>
              </div>
            </div>{" "}
            <div className="accordion-item mb-4">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <span className="text-primary fw-bold">4.</span>
                  <div className="d-flex justify-content-around w-100">
                    <span className="fw-bold">
                      What are some of the best ways to find a job?
                    </span>
                  </div>
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
              <div className="accordion-body ">
            Don't just think in terms of ticking the boxes and meeting the criteria that an employer is looking for.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home3;

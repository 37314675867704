import "./index.scss";

const Footer = () => {
  return (
    <section className="footer">
      <div className="row py-5 text-white">
        <div className="col-lg-2 footer-logo ">
          <img src="assets/logo.png" alt="LOGO" className="img-fluid logo" />
          <h5 className="ms-1 mt-2">Connect with Us</h5>
        </div>
        <div className="col-lg-3 col-md-4 mt-3">
          <h5>Helpful Links</h5>
          <hr className="border border-primary border-2 bg-primary mt-0 opacity-75 w-25" />
          <div className="flex-column d-flex">
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              FAQS
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Privacy Policy
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Terms of Use
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Service Provider Subscription Plan
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Equipment Subscription Plan
            </a>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 mt-3">
          <h5>Quick Links</h5>
          <hr className="border border-primary border-2 bg-primary mt-0 opacity-75 w-25" />
          <div className="flex-column d-flex">
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Home
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              About Us
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              How it Works
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Contact Us
            </a>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 mt-3">
          <h5>For Search</h5>
          <hr className="border border-primary border-2 bg-primary mt-0 opacity-75 w-25" />
          <div className="flex-column d-flex">
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Cinematographer
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Photo Editor
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Photographer
            </a>
            <a className="text-white text-decoration-none fs-5 mb-2" href="/">
              Video Editor
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-3 p-0">
          <div className="d-flex mb-3">
            <div className="rounded-circle bg-white img-fluid p-2">
              <img
                alt=""
                className="gmail-icon"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1024px-Gmail_icon_%282020%29.svg.png"
              />
            </div>
            <h5 className="ms-1 mt-2">Sign Up For a Newsletter</h5>
          </div>
          <div className="bg-light rounded rounded-pill shadow-sm mb-4 p-2">
            <div className="input-group">
              <input
                type="search"
                placeholder="Enter your Email Address"
                aria-describedby="button-addon1"
                className="form-control border-0 bg-light"
              />
              <div className="input-group-append bg-primary text-white rounded-5">
                <button
                  id="button-addon1"
                  type="submit"
                  className="btn btn-link text-white"
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="text-white text-center w-100 pb-2">
        <p>
          @ {new Date().getFullYear()} Camerazi. All Rights Reserved. Design By-
         <span className="fw-bold">   Papaya Coders Pvt. Ltd.</span>
        </p>
      </div>
      <button className="btn btn-primary footer-button text-white shadow fs-5 px-3">
        <i className="fa fa-chevron-down" /> Footer
      </button>
    </section>
  );
};

export default Footer;

import "./index.scss";
const Navbar = () => {
  return (
    <div className="header">
      <div className="header-ad d-flex justify-content-center d-none">
        <img
          src="assets/header-img-3.png"
          alt="HEADER_IMG"
          className="img-fluid header-img-3"
        />
        <img
          src="assets/header-img-3.png"
          alt="HEADER_IMG"
          className="img-fluid header-img-3"
        />
        <img
          src="assets/header-img-3.png"
          alt="HEADER_IMG"
          className="img-fluid header-img-3"
        />
      </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid nav-1">
          <a className="navbar-brand" href="/">
            <img src="assets/logo.png" className="img-fluid logo" alt="LOGO" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item fw-bold">
                <a
                  className="nav-link text-dark fs-5"
                  aria-current="page"
                  href="/"
                >
                  How it Works
                </a>
              </li>
              <li className="nav-item fw-bold">
                <a className="nav-link text-dark fs-5" href="/">
                  Browse Jobs
                </a>
              </li>
            </ul>
            <button className="btn btn-primary fw-bold text-white me-3">
              LOGIN
            </button>
            <button className="btn btn-light me-lg-5 ms-2 fw-bold fs-5 text-primary bg-white">
              REGISTER
            </button>
          </div>
        </div>
      </nav>

      <div className=" bg-primary d-flex justify-content-between navbar d-none d-lg-flex ">
        <div className="nav-2 d-flex flex-lg-row flex-column justify-content-between w-100 align-items-center">
          <div className="d-flex">
            <div className=" dropdown">
              <a
                className="nav-link dropdown-toggle ms-lg-5 text-white fw-bold fs-5 ms-3"
                href="/"
                id="navbarScrollingDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Equipments
              </a>
              <div
                className="dropdown-menu nav-drop"
                aria-labelledby="navbarScrollingDropdown"
              >
                <div className="row dropdown-megamenu">
                  <div className="col-lg-4">
                    {" "}
                    <div className="card shadow bg-body rounded">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/drone.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Aerial Vehicle</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body rounded">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/video-camera.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Digital Camera</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/tripod.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Gimbal</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/lens.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Lens</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/camera.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Monopad</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/photo.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Monopad</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <a
                className="nav-link dropdown-toggle ms-lg-5 text-white fw-bold fs-5 ms-3"
                href="/"
                id="navbarScrollingDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Blogs
              </a>
              <div
                className="dropdown-menu nav-drop"
                aria-labelledby="navbarScrollingDropdown"
              >
                <div className="row dropdown-megamenu">
                  <div className="col-lg-4">
                    {" "}
                    <div className="card shadow bg-body rounded">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/drone.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Aerial Vehicle</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body rounded">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/video-camera.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Digital Camera</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/tripod.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Gimbal</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/lens.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Lens</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/camera.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Monopad</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/photo.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Monopad</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <a
                className="nav-link dropdown-toggle ms-lg-5 text-white fw-bold fs-5 ms-3"
                href="/"
                id="navbarScrollingDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                color="white"
              >
                Plans
              </a>
              <div
                className="dropdown-menu nav-drop"
                aria-labelledby="navbarScrollingDropdown"
              >
                <div className="row dropdown-megamenu">
                  <div className="col-lg-4">
                    {" "}
                    <div className="card shadow bg-body rounded">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/drone.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Aerial Vehicle</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body rounded">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/video-camera.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Digital Camera</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/tripod.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Gimbal</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/lens.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Lens</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/camera.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Monopad</h5>
                      </div>
                    </div>
                    <div className="card shadow bg-body">
                      <div className="item card-body pb-0 rounded my-3 text-center">
                        <img
                          src="assets/photo.png"
                          className="profileImg"
                          alt="PROFILE"
                        />
                        <h5 className="mt-3">Monopad</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="me-lg-5 ">
            <a
              className="text-white fw-bold me-lg-5 ms-2 fs-5 text-decoration-none me-2"
              href="/"
            >
              About Us
            </a>
            <a
              className="text-white fw-bold me-3 fs-5 text-decoration-none"
              href="/"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

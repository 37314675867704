import "./index.scss";

const Home1 = () => {
  return (
    <section className="home-1">
      <div className="video-container">
        <video autoPlay muted loop>
          <source
            src="https://staging.camerazi.com/uploads/banner-video/61e66dfbe4f0f.mp4"
            type="video/mp4"
          />
        </video>
        <div className="row content">
        <div className="col-lg-6 fw-semibold d-flex flex-column title-col-1">
          Hire the best
          <br />
          Talents for your Event,
          <br />
          online.
          <br />
          <div className="fw-normal">
            <ul>
              <li>Post a job and hire a talent</li>
              <li>Browse and apply Assignment</li>
              <li>Sale/ Rent an Equiqment</li>
            </ul>
          </div>
          <button className="btn btn-primary text-white fw-bold w-max">
            Post your Requirement
          </button>
        </div>
      </div>
      </div>
    
    </section>
  );
};

export default Home1;


import './App.css';
import { Fragment } from 'react';
import Navbar from './components/navbar';
import "./index.scss";
import Home1 from './components/home-1';
import Home2 from './components/home-2';
import Home3 from './components/home-3';
import Footer from './components/footer';

function App() {
  return (
   <Fragment>
    <Navbar/>
    <Home1/>
    <hr/>
    <Home2/>
    <Home3/>
    <Footer/>
   </Fragment>
  );
}

export default App;

// import profile from "../../assets/profile.png";
import "./index.scss";
const Home2 = () => {
  return (
    <section className="home-2">
      <div className="row px-lg-5 mt-5">
        <div className="col-lg-9">
          <h2> Get Work done in over 4 different categories</h2>
          <div className="row fw-bold d-flex my-4 categories">
            <div className="col-lg-3 col-md-4">
              <div className="bg-secondary mb-2 bg-opacity-25 rounded text-secondary p-3 d-flex flex-column justify-content-center w-100 ">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src="assets/photographer.png"
                    alt=""
                    className="img-fluid"
                  />
                  300
                  <br /> Users
                </div>
                <b className="text text-center">Photographer</b>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="bg-secondary mb-2 bg-opacity-25 rounded text-secondary p-3 d-flex flex-column justify-content-center w-100 ">
                <div className="d-flex align-items-center  justify-content-center">
                  <img
                    src="assets/image-editing.png"
                    alt=""
                    className="img-fluid"
                  />
                  300
                  <br /> Users
                </div>
                <b className="text text-center">Photo Editor</b>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="bg-secondary mb-2 bg-opacity-25 rounded text-secondary p-3 d-flex flex-column justify-content-center w-100 ">
                <div className="d-flex align-items-center  justify-content-center">
                  <img
                    src="assets/video-chat.png"
                    alt=""
                    className="img-fluid"
                  />
                  300
                  <br /> Users
                </div>
                <b className="text text-center">Video Grapher</b>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="bg-secondary mb-2 bg-opacity-25 rounded text-secondary p-3 d-flex flex-column justify-content-center w-100 ">
                <div className="d-flex align-items-center  justify-content-center">
                  <img
                    src="assets/film-editor.png"
                    alt=""
                    className="img-fluid"
                  />
                  300
                  <br /> Users
                </div>
                <b className="text text-center">Video Editor</b>
              </div>
            </div>
          </div>
          <div className="things-done">
            <h2>You want things done?</h2>
            <div className="row  d-flex mt-4">
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img src="assets/monitor.png" className="img-fluid" alt="" />
                  Post Requirement
                </h6>
                <p>
                  Easily for free post a job.Just provide a title, description,
                  and budegt, and you'll receive Quotation in a regarding the
                  job
                </p>
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img
                    src="assets/briefcase.png"
                    className="img-fluid"
                    alt=""
                  />
                  Select Service Provider
                </h6>
                <p>
                  Nothing is too large or small to do. We have independent
                  Service Providers for jobs of any size and budget, covering 4
                  talents. Any work can be completed
                </p>
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img
                    src="assets/payment-method.png"
                    className="img-fluid"
                    alt=""
                  />
                  Pay Safely
                </h6>
                <p>
                  Only pay for work when it has been completed and you're 100%
                  satisfied with the Quality using our milestone payment system
                </p>
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img src="assets/help.png" className="img-fluid" alt="" />
                  We're here to help
                </h6>
                <p>
                  Our talented team of recrutiers can help you find the best
                  Service Provider for the job even manage the projects for you.
                </p>
              </div>
            </div>
          </div>
          <div className="portal-provides mt-4">
            <h2>Our Portal provides</h2>
            <div className="row d-flex mt-4">
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img
                    src="assets/portfolio.png"
                    className="img-fluid"
                    alt=""
                  />
                  Browse Portfolios
                </h6>
                <p>
                  Find professionals you can <br />
                  trust by looking through
                  <br /> their prior work samples
                  <br /> and profile reviews
                </p>
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img src="assets/bill.png" className="img-fluid" alt="" />
                  Fast Quotes
                </h6>
                <p>
                  Receive quick quotations <br />
                  from our talented service
                  <br /> Providers without
                  <br /> obligation
                </p>
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img
                    src="assets/best-work.png"
                    className="img-fluid"
                    alt=""
                  />
                  Best Work
                </h6>
                <p>
                  Camerazi.com has by fas <br />
                  the largest pool of quality <br />
                  of Photographer, Photo <br />
                  Editor, Videographer,
                  <br /> Video Editor
                </p>
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="fw-bolder d-flex align-items-center">
                  <img
                    src="assets/credit-card.png"
                    className="img-fluid"
                    alt=""
                  />
                  Secure Payment
                </h6>
                <p>
                  Your Payment will be <br />
                  Secure through our <br />
                  Website
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <img src="assets/ad-1.png" alt="ad-1" className="rounded mb-4 ad" />
          <img src="assets/ad-2.png" alt="ad-2" className="rounded mb-4 ad" />
        </div>
      </div>
      <div className="row px-lg-5 mt-4">
        <div className="col-lg-9">
          <div className="row d-flex mt-3 latest-jobs">
            <h2>Latest Jobs</h2>
            <div className="owl-carousel owl-latest-jobs owl-theme">
              <div className="card">
                <div className="item card-body pb-0 rounded my-3">
                  <div className="d-flex justify-content-center">
                    <img
                      src="assets/profile.png"
                      className="profileImg"
                      alt="PROFILE"
                    />
                  </div>
                  <h6 className="ms-3 mt-3">Need a Photographer</h6>
                  <p className="ms-3">
                    <i className="fa fa-clock-o" /> 5 Months ago
                  </p>
                  <div className="d-flex justify-content-between mt-1 ">
                    <p className="ms-3">
                      <i className="fa fa-map-marker" /> Mumbai
                    </p>
                    <p className="ms-3">
                      <i className="fa fa-briefcase" /> Self
                    </p>
                    <p></p>
                  </div>
                  <button className="btn btn-primary text-white w-100">
                    {" "}
                    View
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3">
                  <div className="d-flex justify-content-center">
                    <img
                      src="assets/profile.png"
                      className="profileImg"
                      alt="PROFILE"
                    />
                  </div>
                  <h6 className="ms-3 mt-3">Need a Photographer</h6>
                  <p className="ms-3">
                    <i className="fa fa-clock-o" /> 5 Months ago
                  </p>
                  <div className="d-flex justify-content-between mt-1">
                    <p className="ms-3">
                      <i className="fa fa-map-marker" /> Mumbai
                    </p>

                    <p className="ms-3">
                      <i className="fa fa-briefcase" /> Self
                    </p>
                    <p></p>
                  </div>
                  <button className="btn btn-primary text-white w-100">
                    {" "}
                    View
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3">
                  <div className="d-flex justify-content-center">
                    <img
                      src="assets/profile.png"
                      className="profileImg"
                      alt="PROFILE"
                    />
                  </div>
                  <h6 className="ms-3 mt-3">Need a Photographer</h6>
                  <p className="ms-3">
                    <i className="fa fa-clock-o" /> 5 Months ago
                  </p>
                  <div className="d-flex justify-content-between mt-1">
                    <p className="ms-3">
                      <i className="fa fa-map-marker" /> Mumbai
                    </p>

                    <p className="ms-3">
                      <i className="fa fa-briefcase" /> Self
                    </p>
                    <p></p>
                  </div>
                  <button className="btn btn-primary text-white w-100">
                    {" "}
                    View
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3">
                  <img
                    src="assets/profile.png"
                    className="profileImg"
                    alt="PROFILE"
                  />
                  <h6 className="ms-3 mt-3">Need a Photographer</h6>
                  <p className="ms-3">
                    <i className="fa fa-clock-o" /> 5 Months ago
                  </p>
                  <div className="d-flex justify-content-between mt-1">
                    <p className="ms-3">
                      <i className="fa fa-map-marker" /> Mumbai
                    </p>

                    <p className="ms-3">
                      <i className="fa fa-briefcase" /> Self
                    </p>
                    <p></p>
                  </div>
                  <button className="btn btn-primary text-white w-100">
                    {" "}
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex mt-3 equipment-provider">
            <div className="d-flex align-items-center justify-content-between py-3 px-3">
              <h5>Equipment Provider</h5>
              <a href="/" className="text-decoration-none text-primary">
                View All <i className="fa fa-arrow-right" />
              </a>
            </div>
            <div className="owl-carousel owl-equipment-provider owl-theme">
              <div className="card">
                <div className="item card-body pb-0 rounded my-3 text-center">
                  <div className="d-flex justify-content-center">
                  <img
                    src="assets/drone.png"
                    className="productsImg"
                    alt="PROFILE"
                  />
                  </div>
                  <h5 className="mt-3">Aerial Vehicle</h5>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3 text-center">
                <div className="d-flex justify-content-center">
                  <img
                    src="assets/video-camera.png"
                    className="productsImg"
                    alt="PROFILE"
                  />
                  </div>
                  <h5 className="mt-3">Digital Camera</h5>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3 text-center">
                <div className="d-flex justify-content-center">
                  <img
                    src="assets/tripod.png"
                    className="productsImg"
                    alt="PROFILE"
                  />
                  </div>
                  <h5 className="mt-3">Gimbal</h5>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3 text-center">
                <div className="d-flex justify-content-center">
                  <img
                    src="assets/lens.png"
                    className="productsImg"
                    alt="PROFILE"
                  />
                  </div>
                  <h5 className="mt-3">Lens</h5>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3 text-center">
                <div className="d-flex justify-content-center">
                  <img
                    src="assets/camera.png"
                    className="productsImg"
                    alt="PROFILE"
                  />
                  </div>
                  <h5 className="mt-3">Monopad</h5>
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-3 text-center">
                <div className="d-flex justify-content-center">
                  <img
                    src="assets/photo.png"
                    className="productsImg"
                    alt="PROFILE"
                  />
                  </div>
                  <h5 className="mt-3">Monopad</h5>
                </div>
              </div>
            </div>
          </div>
          {/* Products Carousel */}
          <div className="row d-flex mt-3 products">
            <div className="owl-carousel owl-product-carousel owl-theme">
              <div className="card">
                <div className="item card-body pb-0 rounded my-2">
                  <img
                    src="https://camerajabber.com/wp-content/uploads/2020/03/Canon_EOS-1D_X_Mark_III_DSC5892-1410x793.jpg"
                    className="product-img"
                    alt="PROFILE"
                  />
                  <h6 className="mt-1 fw-bold">EOS 1DX Mark |||</h6>
                  <p className="mt-2">Canon</p>
                  <div className="bg-secondary rounded px-3 py-1 bg-opacity-25 mb-2">
                    2 Providers Available
                  </div>
                  <button className="btn btn-primary text-white w-100 p-2">
                    {" "}
                    View
                  </button>
                </div>
                <div className="badge status bg-danger w-max rounded-0 p-2 ">
                  Rent,Sale
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-2">
                  <img
                    src="https://i.pcmag.com/imagery/reviews/00dwgAPGRRzr1GESw8jaRia-11.fit_scale.size_760x427.v1569473845.jpg"
                    className="product-img"
                    alt="PROFILE"
                  />
                  <h6 className=" mt-1 fw-bold">Yuneec Mantis G</h6>
                  <p className=" mt-2 "> Yuneec</p>
                  <div className="bg-secondary rounded px-3 py-1 bg-opacity-25 mb-2 ">
                    1 Providers Available
                  </div>
                  <button className="btn btn-primary text-white w-100 p-2">
                    {" "}
                    View
                  </button>
                </div>
                <div className="badge bg-danger w-max rounded-0 p-2 status">
                  Rent,Sale
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-2">
                  <img
                    src="https://m.media-amazon.com/images/I/613YqAs5n3L._SL1000_.jpg"
                    className="product-img"
                    alt="PROFILE"
                  />
                  <h6 className=" mt-1 fw-bold">D7500</h6>
                  <p className=" mt-2"> Nikon</p>
                  <div className="bg-secondary rounded px-3 py-1 bg-opacity-25 mb-2 ">
                    1 Providers Available
                  </div>
                  <button className="btn btn-primary text-white w-100 p-2">
                    {" "}
                    View
                  </button>
                </div>
                <div className="badge bg-danger w-max rounded-0 p-2 status">
                  Sale
                </div>
              </div>
              <div className="card">
                <div className="item card-body pb-0 rounded my-2">
                  <img
                    src="https://camerajabber.com/wp-content/uploads/2020/03/Canon_EOS-1D_X_Mark_III_DSC5892-1410x793.jpg"
                    className="product-img"
                    alt="PROFILE"
                  />
                  <h6 className="mt-1 fw-bold">EOS 1DX Mark |||</h6>
                  <p className="mt-2 ">Canon</p>
                  <div className="bg-secondary rounded px-3 py-1 bg-opacity-25 mb-2">
                    2 Providers Available
                  </div>
                  <button className="btn btn-primary text-white w-100 p-2">
                    {" "}
                    View
                  </button>
                </div>
                <div className="badge status bg-danger w-max rounded-0 p-2">
                  Rent,Sale
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <img src="assets/ad-3.png" alt="ad-3" className="rounded mb-5 ad" />
          <img src="assets/ad-4.png" alt="ad-4" className="rounded mb-5 ad" />
        </div>
      </div>
    </section>
  );
};
export default Home2;
